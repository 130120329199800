import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "クライアント認証と Path Based Routing が必要なサーバを AWS で構築（前編：Proxy 層）",
  "date": "2017-08-24T02:00:00.000Z",
  "slug": "entry/2017/08/24/120000_01",
  "tags": ["medley"],
  "hero": "./2017_08_24_01.png",
  "heroAlt": "routing"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`今回の内容について`}</h1>
    <p>{`メドレー開発本部の`}<a parentName="p" {...{
        "href": "https://www.wantedly.com/companies/medley/post_articles/57252"
      }}>{`田中`}</a>{`です。
先日、Proxy 層を Elastic Beanstalk 上の Nginx で、App 層を EC2 インスタンスで構築する機会がありました。ここだけ見るととても普通に見えますが、後述する制約から苦労した点もあり、制約を乗り越えるための工夫も含めてお話できる限り共有させていただきます（先にお伝えしておくと、特殊な事情がなければ今回のようなケースでは ALB で対応する ECS サービスに Path Based Routing してやるのが良いと思います）。`}</p>
    <p>{`技術要素として、Nginx（OpenResty）/ Route53 Private DNS / EC2 / Systems Manager パラメータストア あたりに触れたいと思います。（Beanstalk は Multicontainer Docker を使用し、それも慣れるまでちょっとクセあったなぁと思ったのですが分量が多くなりそうなのでまた別の機会に共有させて頂きます）`}</p>
    <p>{`まず前編として Proxy 層、主に Nginx を使用した Dynamic Path Based Routing についてお話して、`}<a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/24/120000_02"
      }}>{`後編は App 層`}</a>{`について、EC2 と Systems Manager パラメータストアあたりについて共有させていただければと思います。`}</p>
    <h1>{`設計/構築する上での前提と方針`}</h1>
    <p>{`対象となる案件を進める上での要件・制限内容は諸事情あり、ざっとまとめるとこのような感じです。`}</p>
    <ul>
      <li parentName="ul">{`環境は AWS を使用する`}</li>
      <li parentName="ul">{`サーバアプリケーション、クライアントアプリケーションはユーザ毎で、サーバアプリケーションは共用できない（ユーザが増える度にクライアント/サーバのセットが増えるイメージ）`}</li>
      <li parentName="ul">{`ただし、クライアントからの接続先となる Endpoint は同じだが、Host Based Routing は訳あって利用できない`}</li>
      <li parentName="ul">{`クライアント認証を使用する`}</li>
    </ul>
    <p>{`上記から、以下の設計方針で進める事にしました。`}</p>
    <ul>
      <li parentName="ul">{`Proxy 層でクライアント認証を行い、Path Based Routing で対象となるサーバにリクエストを proxy する。Path 部分にクライアント別の識別 ID を含め、その値を元に Private DNS で名前解決する`}
        <ul parentName="li">
          <li parentName="ul">{`例) `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`https://example.com/a-client/api`}</code>{` => `}<code parentName="li" {...{
              "className": "language-text"
            }}>{`https://a-client.local/api`}</code></li>
          <li parentName="ul">{`App 層は個別 EC2 インスタンスとする`}</li>
        </ul>
      </li>
    </ul>
    <h1>{`設計する上で悩んだ点`}</h1>
    <p>{`主に 2 点ありますが、まずは Proxy 層です。出来るだけ AWS のマネージド・サービスで済ませたかったので、クライアント認証と Path Based Routing が可能でやりたい事に合うかどうか調べましたが以下の理由で断念し、普通（?）に ELB + Nginx を利用することにしました。`}</p>
    <ul>
      <li parentName="ul">{`ALB: クライアント認証に非対応。また SSL 終端となるので Nginx 側でクライアント認証が出来ない`}</li>
      <li parentName="ul">{`API GW: クライアント認証は対応しており Routing 部分もがんばればいけるかも？、と思ったが Proxy 先が動的に増えたリするので管理ふくめ難しそうであった`}</li>
    </ul>
    <p>{`次に App 層の構成をどうするかでした。集積度を高めるためにコンテナ利用も検討したのですが、使用するアプリケーションの必要スペックや要件などからいまいちフィットせず、個別の EC2 インスタンスにすることにしました（今でももっと良い方法がないか悩んでたりします）`}</p>
    <h1>{`全体構成`}</h1>
    <p>{`出来上がった全体構成のイメージは以下となります。なお台数は実環境と異なり、今回の内容と関係ない部分などは省略しています。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20170823/20170823180401.png",
      "alt": "20170823180401.png"
    }}></img>
    <p>{`次に、今回の本題となる Proxy 層の構成について触れたいと思います。`}</p>
    <h1>{`Proxy 層の構成`}</h1>
    <p>{`Proxy 層の方針等はまとめると以下の通りで、proxy 先の動的判定と名前解決する箇所がキモとなります。`}</p>
    <ul>
      <li parentName="ul">{`App 層のインスタンスは、起動時に自身の内部 IP と Tag に設定したクライアント識別 ID を元に Route53 の PrivateDNS に登録する`}
        <ul parentName="li">
          <li parentName="ul">{`クライアント識別 ID が a-client の場合、a-client.local のように登録`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Proxy 層の Nginx はクライアント認証を行い、リクエストパスから取り出したクライアント識別 ID を元に転送先 Endpoint を生成し、backend に proxy する`}
        <ul parentName="li">
          <li parentName="ul">{`App 層のインスタンスは動的に増えるため、リクエスト時に名前解決したい（インスタンスが増える度に自動で Nginx の conf を編集することも検討したが追加数が読めず、conf がふくれあがるのもなぁ、、、という思いがあり止めました）`}</li>
        </ul>
      </li>
    </ul>
    <p>{`Nginx は backend が増えても起動しっぱなしで動的に名前解決して動作させたかったため、lua-nginx-module を導入し `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`balancer_by_lua`}</code>{` ディレクティブと `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lua-resty-dns`}</code>{` モジュールを使用することとし、構築の手間の関係から OpenResty を導入することにしました。`}</p>
    <h2>{`lua-nginx-module を使用した conf ファイル`}</h2>
    <p>{`conf ファイル全体としては以下となります（関係ない箇所は省いています）。ポイントと記載した部分についての説明は後述します。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "nginx"
    }}><pre parentName="div" {...{
        "className": "language-nginx"
      }}><code parentName="pre" {...{
          "className": "language-nginx"
        }}><span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`http`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`upstream`}</span>{` app`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# ポイント 1.`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`# Private DNS で設定した IP（CNAME に設定）を元に動的 Routing`}</span>{`
        `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`balancer_by_lua_block`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            local balancer = require "ngx.balancer"


            local host = ngx.ctx.upstream_server.cname
            local port = '8888'


            local ok, err = balancer.set_current_peer(host, port)
            if not ok then
                return ngx.exit(500)
            end
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`


    `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`server`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`listen`}</span>{` `}<span parentName="span" {...{
              "className": "token number"
            }}>{`443`}</span>{` ssl`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


        `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`set`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$proxy_upstream_host`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`''`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`set`}</span>{` `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$proxy_upstream_domain`}</span>{` `}<span parentName="span" {...{
              "className": "token string"
            }}>{`'.local'`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


        `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`location`}</span>{` ^~ /api/`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
           `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`rewrite_by_lua_block`}</span></span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`


               -- `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`path`}</span>{` からクライアント識別 ID を取得し、Private DNS に設定したドメインを生成
               -- https://example.com/<id>/api という形式のリクエストから、<id>.local というドメインを生成して
               -- ngx.var.proxy_upstream_host 変数に格納
               local ngx_re = require `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"ngx.re"`}</span>{`
               local res, err = ngx_re.split(ngx.var.request_uri, `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"/"`}</span>{`, nil,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`pos = 0`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`)
               `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`local`}</span>{` id = res[3]
               ngx.var.proxy_upstream_host = id..ngx.var.proxy_upstream_domain`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


               -- `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`resolver`}</span>{` 設定
               local resolver = require `}<span parentName="span" {...{
              "className": "token string"
            }}>{`"resty.dns.resolver"`}</span>{`
               local r, err = resolver:new`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
                   `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`nameservers`}</span>{` =`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`"x.x.x.x", 53`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`, -- 使用する nameserver
               `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
               `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`if`}</span>{` not r then
                   ngx.exit(ngx.HTTP_INTERNAL_SERVER_ERROR)
               end


               -- ポイント 2.
               -- 生成したドメイン名（<id>.local）を元に名前解決し、取得した結果を ngx.ctx にセット
               --  （balancer_by_lua_block で使用する）
               local answers, err = r:query(ngx.var.proxy_upstream_host,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` qtype = r.TYPE_CNAME `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`)
               if not answers then
                   ngx.exit(ngx.HTTP_INTERNAL_SERVER_ERROR)
               end
               if answers.errcode then
                   ngx.exit(ngx.HTTP_INTERNAL_SERVER_ERROR)
               end
               ngx.ctx.upstream_server = answers[1]
           `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`


           `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`proxy_set_header`}</span>{` Host `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$host`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
           `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`proxy_set_header`}</span>{` X-Real-IP `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$remote_addr`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
           `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`proxy_set_header`}</span>{` X-Forwarded-For `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$proxy_add_x_forwarded_for`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
           `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`proxy_set_header`}</span>{` X-Forwarded-Proto `}<span parentName="span" {...{
              "className": "token variable"
            }}>{`$scheme`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`


           -- `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`https://<id>.local/api`}</span>{` に proxy
           rewrite ^/api/(.+)$ /api/ break`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
           `}<span parentName="code" {...{
            "className": "token directive"
          }}><span parentName="span" {...{
              "className": "token keyword"
            }}>{`proxy_pass`}</span>{` https://app`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h3>{`ポイント 1. 動的 Routing`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`balancer.set_current_peer`}</code>{` にて proxy 先を動的に設定します。`}</p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`host`}</code>{` 部分にはドメインを直接指定することができないため、ポイント 2. で `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ngx.ctx`}</code>{` にセットした DNS の値から IP（Route53 に CNAME レコードとして設定している）を指定しています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lua"
    }}><pre parentName="div" {...{
        "className": "language-lua"
      }}><code parentName="pre" {...{
          "className": "language-lua"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`balancer_by_lua_block`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`local`}</span>{` balancer `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` require `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ngx.balancer"`}</span>{`


    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`-- ngx.ctx にセットしていた、Private DNS から取得した内部 IP をセット`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`local`}</span>{` host `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` ngx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ctx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`upstream_server`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cname
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`local`}</span>{` port `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'8888'`}</span>{`


    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`-- proxy 先セット。host にドメインは直接指定できない`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`local`}</span>{` ok`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` balancer`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`set_current_peer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`host`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` port`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`not`}</span>{` ok `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` ngx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`500`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`詳細については OpenResty の`}<a parentName="p" {...{
        "href": "https://github.com/openresty/lua-resty-core/blob/master/lib/ngx/balancer.md"
      }}>{`ドキュメント`}</a>{`を参照してください`}</p>
    <h3>{`ポイント 2. 動的名前解決`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`r:query`}</code>{` にて、生成したドメイン名（`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`<id>.local`}</code>{`）を問い合わせます。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`r`}</code>{` 部分は `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`resolver:new`}</code>{` で nameserver を指定した resolver となります。`}</p>
    <p>{`なお、nameserver に指定する IP は今回は Route53 の Private DNS を指定するため、外部 nameserver ではなくローカルの nameserver（10.0.0.2 など）を指定することになります。`}</p>
    <p>{`問い合わせ結果の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`answers`}</code>{`部分は Lua table 形式の配列となります。今回の例でいうと対象は 1 件となるので、その値を`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`balancer_by_lua_block`}</code>{`で使用するために`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`ngx.ctx`}</code>{`にセットしています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "lua"
    }}><pre parentName="div" {...{
        "className": "language-lua"
      }}><code parentName="pre" {...{
          "className": "language-lua"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`local`}</span>{` answers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` err `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`query`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ngx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`var`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`proxy_upstream_host`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` qtype `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` r`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`TYPE_CNAME `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`not`}</span>{` answers `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
    ngx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ngx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`HTTP_INTERNAL_SERVER_ERROR`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` answers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`errcode `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`then`}</span>{`
    ngx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`exit`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`ngx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`HTTP_INTERNAL_SERVER_ERROR`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`end`}</span>{`
ngx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`ctx`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`upstream_server `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` answers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`1`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span></code></pre></div>
    <p>{`詳細については OpenResty の`}<a parentName="p" {...{
        "href": "https://github.com/openresty/lua-resty-dns"
      }}>{`ドキュメント`}</a>{`を参照してください`}</p>
    <h1>{`今回のまとめ`}</h1>
    <p>{`upstream 先を動的に判定して proxy するという要件はそうそう無いかもしれませんし、途中までは複雑な構成になりそうだなぁとドキドキしてしましたが、結果としてはそれなりにシンプルになったかなと思います。今更ながら Nginx（と lua module）は柔軟で良く出来てるなぁという感想でした。`}</p>
    <p>{`後編は App 層について、EC2 と Systems Manager パラメータストアあたりについて共有させていただければと思います。`}</p>
    <p><a parentName="p" {...{
        "href": "https://developer.medley.jp/entry/2017/08/24/120000_02"
      }}>{`https://developer.medley.jp/entry/2017/08/24/120000_02`}</a></p>
    <h1>{`参考リンク`}</h1>
    <p>{`構築にあたり、下記記事を参考にさせていただきました。ありがとうございます。`}</p>
    <p><a parentName="p" {...{
        "href": "https://qiita.com/toritori0318/items/a9305d528b52936c0573"
      }}>{`https://qiita.com/toritori0318/items/a9305d528b52936c0573`}</a></p>
    <h1>{`お知らせ`}</h1>
    <p>{`メドレーでは、医師たちがつくるオンライン医療事典「`}<a parentName="p" {...{
        "href": "https://medley.life/"
      }}>{`MEDLEY`}</a>{`」、オンライン診療アプリ「`}<a parentName="p" {...{
        "href": "https://clinics.medley.life/"
      }}>{`CLINICS`}</a>{`」、医療介護の求人サイト「`}<a parentName="p" {...{
        "href": "https://job-medley.com/"
      }}>{`ジョブメドレー`}</a>{`」、口コミで探せる介護施設の検索サイト「`}<a parentName="p" {...{
        "href": "https://www.kaigonohonne.com/"
      }}>{`介護のほんね`}</a>{`」などのプロダクトを提供しています。これらのサービスの拡大を受けて、その成長を支えるエンジニア・デザイナーを募集しています。`}</p>
    <p>{`メドレーで一緒に医療体験を変えるプロダクト作りに関わりたい方のご連絡お待ちしております。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/recruit/creative.html"
      }}>{`https://www.medley.jp/recruit/creative.html`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      